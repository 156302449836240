// CON COMPONENTE
// body {
//   /* solid background */
//   background: rgb(0, 212, 255);

//   /* gradient background*/
//   background: linear-gradient(
//     45deg,
//     rgba(0, 212, 255, 1) 0%,
//     rgba(11, 3, 45, 1) 100%
//   );

//   /* photo background */
//   background-image: url(../../../developsoft/images/landing/fondo-develop.jpg);
//   background-size: cover;
//   background-position: center;

//   display: flex;
//   align-items: center;
//   justify-content: center;
//   height: 100vh;
// }
.landing-services-background-image-gradiant {
  background: linear-gradient(
      0deg,
      rgba(49, 27, 146, 0.8),
      rgba(13, 71, 161, 0.8)
    ),
    radial-gradient(77.36% 256.97% at 77.36% 57.52%, #ff8800 0%, #330ae7 100%);
}

@mixin mediaBackgroundImage() {
  @media screen and (min-width: 1328px) {
    background-image: url(../../../developsoft/images/landing/customer-team-develop.jpg);
    img {
      height: 50%;
      width: 60%;
    }
  }
  @media screen and (min-width: 480px) and (max-width: 1327px) {
    background-image: url(../../../developsoft/images/landing/wall-develop.jpg);
    background-position: right;
  }
  @media screen and (max-width: 480px) {
    background-image: url(../../../developsoft/images/landing/wall-develop.jpg);
  }
}

.landing-services-background-image {
  background-size: auto 100%;
  @include mediaBackgroundImage();
}

@mixin mediaDevelopsoftDesign() {
  @media screen and (min-width: 1328px) {
    background-image: url(../../../developsoft/images/landing/logo-chip-developsoft.jpg);
    background-size: 100% auto;
    h3 {
      font-size: 1.5rem;
    }
    .landing-banner-backgraound-text {
      font-size: 1.5rem;
    }
  }
  @media screen and (min-width: 480px) and (max-width: 1327px) {
    background-image: url(../../../developsoft/images/landing/logo-chip-developsoft.jpg);
    background-size: 100% 100%;
    background-position: right;
    .landing-banner-backgraound-text {
      font-size: 1.25rem;
    }
  }
  @media screen and (max-width: 480px) {
    background-image: url(../../../developsoft/images/landing/logo-chip-developsoft.jpg);
    background-position: center;
    background-size: auto 100%;
    .landing-banner-backgraound-text {
      font-size: 1rem;
    }
  }
}

.landing-background-developsoft-design {
  border-radius: var(--border-radius);
  @include mediaDevelopsoftDesign();

  p {
    font-family: "Poppins-light";
    font-size: small;
  }
}

.landing-services {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 1rem;
  padding-bottom: 1rem;

  @mixin visibleDesktop() {
    @media screen and (min-width: 1328px) {
      background-image: url(../../../developsoft/images/landing/logo-chip-developsoft.jpg);
      background-size: 100% auto;
    }
    @media screen and (min-width: 480px) and (max-width: 1327px) {
      background-image: url(../../../developsoft/images/landing/logo-chip-developsoft.jpg);
      background-size: 100% 100%;
      background-position: right;
    }
    @media screen and (max-width: 480px) {
      background-image: url(../../../developsoft/images/landing/logo-chip-developsoft.jpg);
      background-position: center;
      background-size: auto 100%;
    }
  }

  .container-landing-card {
    background-color: var(--background-color);
    border: 1px solid var(--border-color);
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 1.25rem;
    border-radius: var(--border-radius);
  }
  // CARD TRANSPARENTE DIFUMINADO
  // .container-landing-card {
  //   backdrop-filter: blur(16px) saturate(180%);
  //   -webkit-backdrop-filter: blur(16px) saturate(180%);
  //   background-color: rgba(0, 0, 0, 0.25);
  //   border-radius: var(--border-radius);
  //   border: 1px solid rgba(255, 255, 255, 0.125);
  //   padding: 15px;
  //   filter: drop-shadow(0 30px 10px rgba(0, 0, 0, 0.125));
  //   display: flex;
  //   flex-direction: column;
  //   align-items: center;
  //   justify-content: center;
  //   text-align: center;
  //   margin: 1.25rem;
  // }

  .wrapper-landing-card {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    padding-left: 1rem;
    padding-right: 1rem;

    width: 100%;
    height: 100%;
  }

  .banner-image-landing-card {
    background-image: url(../../../developsoft/images/landing/fondo-develop.jpg);
    background-position: center;
    background-size: cover;
    height: 300px;
    width: 100%;
    border-radius: var(--border-radius);
    border: 1px solid rgba(255, 255, 255, 0.255);
  }

  h1 {
    font-family: "Poppins-Bold", poppins;
    color: var(--primary-color-text);
    // text-transform: uppercase;
    font-size: 1.75rem;
  }

  p {
    color: #fff;
    text-align: center;
    font-size: 0.9rem;
    line-height: 150%;
    letter-spacing: 2px;
    width: 300px;
    font-family: "Poppins-Light";
    // text-transform: uppercase;
    // white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .button-wrapper-landing-card {
    margin-top: 18px;
  }

  .btn {
    border: none;
    padding: 12px 24px;
    border-radius: 10px;
    font-size: 12px;
    font-size: 0.8rem;
    letter-spacing: 2px;
    cursor: pointer;
  }

  .btn + .btn {
    margin-left: 10px;
  }

  .outline {
    background: transparent;
    color: var(--primary-color-text);
    border: 1px solid var(--primary-color-text);
    transition: all 0.1s ease;
  }

  .outline:hover {
    transform: scale(1.125);
    background: var(--secundary-color);
    color: rgba(255, 255, 255, 0.9);
    border-color: rgba(255, 255, 255, 0.9);
    transition: all 0.1s ease;
  }

  .fill {
    background: var(--primary-color);
    color: rgba(255, 255, 255, 0.95);
    filter: drop-shadow(0);
    font-weight: bold;
    transition: all 0.1s ease;
  }

  .fill:hover {
    transform: scale(1.125);
    background: var(--secundary-color);
    border-color: rgba(255, 255, 255, 0.9);
    filter: drop-shadow(0 10px 5px rgba(0, 0, 0, 0.125));
    transition: all 0.1s ease;
  }
}
