.layout-topbar {
  position: fixed;
  height: 5rem;
  z-index: 997;
  left: 0;
  top: 0;
  width: 100%;
  padding: 0 2rem;
  background-color: var(--surface-card);
  transition: left $transitionDuration;
  display: flex;
  align-items: center;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05),
    0px 1px 4px rgba(0, 0, 0, 0.08);

  .layout-topbar-logo {
    display: flex;
    align-items: center;
    color: var(--surface-900);
    font-size: 1.5rem;
    font-weight: 500;
    width: 300px;
    border-radius: 12px;

    img {
      height: 2.5rem;
      margin-right: 0.5rem;
    }

    &:focus {
      @include focused();
    }
  }

  .layout-topbar-button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: var(--text-color-secondary);
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    cursor: pointer;
    transition: background-color $transitionDuration;
    background-color: var(--surface-200);
    &:hover {
      color: var(--primary-color-text);
      background-color: var(--primary-color);
    }

    &:focus {
      @include focused();
    }

    i {
      font-size: 1.5rem;
    }

    span {
      font-size: 1rem;
      display: none;
    }
  }

  .layout-topbar-button-hidden {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: var(--text-color-secondary);
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    cursor: pointer;
    transition: background-color $transitionDuration;
    background-color: var(--surface-200);
    &:hover {
      color: var(--primary-color-text);
      background-color: var(--primary-color);
    }

    &:focus {
      @include focused();
    }

    i {
      font-size: 1.5rem;
    }

    span {
      font-size: 1rem;
      display: none;
    }
  }

  .layout-menu-button {
    margin-left: 2rem;
  }

  .layout-topbar-menu-button {
    display: none;

    i {
      font-size: 1.25rem;
    }
  }

  .layout-topbar-menu {
    margin: 0 0 0 auto;
    padding: 0;
    list-style: none;
    display: flex;

    .layout-topbar-button {
      margin-left: 1rem;
    }

    .layout-topbar-button-hidden {
      margin-left: 1rem;
      display: none;
    }

    // avatar-user
    .content-user-right {
      display: flex;

      position: relativate;
      border: 2px solid var(--white);
      color: var(--black);
      background: var(--surface-200);
      transition: color 0.5s, background 0.5s;
      border-radius: 30px;
      padding-right: 1.25rem;
      transition: background-color $transitionDuration;
      background-color: var(--surface-300);
      &:hover {
        color: var(--primary-color-text);
        background-color: var(--primary-color);
      }

      &:focus {
        @include focused();
      }
      .user-avatar {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        position: relative;
        color: var(--text-color-secondary);
        border-radius: 50%;
        width: 3rem;
        height: 3rem;
        cursor: pointer;
        transition: background-color $transitionDuration;
        background-color: var(--surface-300);
        &:hover {
          color: var(--primary-color-text);
          background-color: var(--primary-color);
        }

        &:focus {
          @include focused();
        }

        i {
          font-size: 1.5rem;
        }

        span {
          font-size: 1rem;
          display: none;
        }
      }
      .user-data {
        user-select: none;
        text-align: left;
        padding-left: 0.5rem;
        .user-data-text {
          font-weight: 700;
          font-size: large;
        }
        .user-data-subcontent {
          font-size: small;
        }
      }
    }
    .content-user-left {
      display: flex;

      position: relativate;
      border: 2px solid var(--white);
      color: var(--black);
      background: var(--surface-200);
      transition: color 0.5s, background 0.5s;
      border-radius: 30px;
      padding-left: 1.25rem;
      transition: background-color $transitionDuration;
      background-color: var(--surface-300);
      &:hover {
        color: var(--primary-color-text);
        background-color: var(--primary-color);
      }

      &:focus {
        @include focused();
      }
      .user-avatar {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        position: relative;
        color: var(--text-color-secondary);
        border-radius: 50%;
        width: 3rem;
        height: 3rem;
        cursor: pointer;
        transition: background-color $transitionDuration;
        background-color: var(--surface-300);
        &:hover {
          color: var(--primary-color-text);
          background-color: var(--primary-color);
        }

        &:focus {
          @include focused();
        }

        i {
          font-size: 1.5rem;
        }

        span {
          font-size: 1rem;
          display: none;
        }
      }
      .user-data {
        user-select: none;
        text-align: right;
        padding-right: 0.5rem;
        .user-data-text {
          font-weight: 700;
          font-size: large;
        }
        .user-data-subcontent {
          font-size: small;
        }
      }
    }

    //Notificaciones
    #notication_menu_num {
      position: absolute;
      top: 0%;
      left: 55%;
      background: var(--primary-color);
      width: 1.3rem;
      height: 1.3rem;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: small;
      color: var(--primary-color-text);
      padding: 2px;
      padding-top: 4px;
      :hover {
        background: var(--secundary-color);
      }
      :focus {
        :hover {
          background: var(--secundary-color);
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .layout-topbar {
    justify-content: space-between;

    .layout-topbar-logo {
      width: auto;
      order: 2;
    }

    .layout-menu-button {
      margin-left: 0;
      order: 1;
    }

    .layout-topbar-menu-button {
      display: inline-flex;
      margin-left: 0;
      order: 3;
    }

    .layout-topbar-menu {
      margin-left: 0;
      position: absolute;
      flex-direction: column;
      background-color: var(--surface-overlay);
      box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02),
        0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08);
      border-radius: 12px;
      padding: 1rem;
      right: 2rem;
      top: 5rem;
      min-width: 18rem;
      display: none;
      -webkit-animation: scalein 0.15s linear;
      animation: scalein 0.15s linear;

      &.layout-topbar-menu-mobile-active {
        display: block;
      }

      .layout-topbar-button {
        margin-left: 0;
        margin-top: 0.25rem;
        display: flex;
        width: 100%;
        height: auto;
        justify-content: flex-start;
        border-radius: 12px;
        padding: 1rem;

        i {
          font-size: 1rem;
          margin-right: 0.5rem;
        }

        span {
          font-weight: medium;
          display: block;
        }
      }
      .layout-topbar-button-hidden {
        margin-left: 0;
        display: flex;
        width: 100%;
        height: auto;
        justify-content: flex-start;
        border-radius: 12px;
        padding: 1rem;

        i {
          font-size: 1rem;
          margin-right: 0.5rem;
        }

        span {
          font-weight: medium;
          display: block;
        }
      }
    }

    //Notificaciones movíl
    #notication_menu_num {
      position: absolute;
      top: 0;
      left: 80%;
      background: var(--primary-color);
      width: 50rem;
      height: 50rem;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: x-large;
      color: white;
      padding: 2px;
    }
  }
}
