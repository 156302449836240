.stickmenu-topbar {
  position: fixed;
  height: 5rem;
  z-index: 997;
  left: 0;
  top: 0;
  width: 100%;
  padding: 0 2rem;
  background-color: var(--surface-card);
  transition: left $transitionDuration;
  display: flex;
  align-items: center;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05),
    0px 1px 4px rgba(0, 0, 0, 0.08);

  .stickmenu-topbar-logo {
    display: flex;
    align-items: center;
    color: var(--surface-900);
    font-size: 1.5rem;
    font-weight: 500;
    width: 300px;
    border-radius: 12px;

    img {
      height: 2.5rem;
      margin-right: 0.5rem;
    }

    &:focus {
      @include focused();
    }
  }

  .stickmenu-topbar-button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: var(--text-color-secondary);
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    cursor: pointer;
    transition: background-color $transitionDuration;

    &:hover {
      color: var(--text-color);
      background-color: var(--surface-hover);
    }

    &:focus {
      @include focused();
    }

    i {
      font-size: 1.5rem;
    }

    span {
      font-size: 1rem;
      display: none;
    }
  }

  .stickmenu-menu-button {
    margin-left: 2rem;
  }

  .stickmenu-topbar-menu-button {
    display: none;

    i {
      font-size: 1.25rem;
    }
  }

  .stickmenu-topbar-menu {
    margin: 0 0 0 auto;
    padding: 0;
    list-style: none;
    display: flex;

    .stickmenu-topbar-button {
      margin-left: 1rem;
    }
  }
}

@media (max-width: 1200px) {
  .stickmenu-topbar {
    justify-content: space-between;

    .stickmenu-topbar-logo {
      width: auto;
      order: 2;
    }

    .stickmenu-menu-button {
      margin-left: 0;
      order: 1;
    }

    .stickmenu-topbar-menu-button {
      display: inline-flex;
      margin-left: 0;
      order: 3;
    }

    .stickmenu-topbar-menu {
      margin-left: 0;
      position: absolute;
      flex-direction: column;
      background-color: var(--surface-overlay);
      box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02),
        0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08);
      border-radius: 12px;
      padding: 1rem;
      right: 2rem;
      top: 5rem;
      min-width: 15rem;
      display: none;
      -webkit-animation: scalein 0.15s linear;
      animation: scalein 0.15s linear;

      &.stickmenu-topbar-menu-mobile-active {
        display: block;
      }

      .stickmenu-topbar-button {
        margin-left: 0;
        display: flex;
        width: 100%;
        height: auto;
        justify-content: flex-start;
        border-radius: 12px;
        padding: 1rem;

        i {
          font-size: 1rem;
          margin-right: 0.5rem;
        }

        span {
          font-weight: medium;
          display: block;
        }
      }
    }
  }
}

// My Landing
// .background-landing {
//   // background: transparentize($color: #09df50, $amount: 0);
//   // background-color: rgba(200, 71, 161, 0.9);
//   background-position: center;
//   background-repeat: no-repeat;
//   background-size: cover;
//   position: relative;

//   background-image: url("/assets/developsoft/image/fondo-develop.jpg");
//   backdrop-filter: green;
//   clip-path: polygon(50% 0%, 100% 0, 100% 43%, 93% 87%, 42% 100%, 32% 100%, 0 100%, 0% 43%, 0 0);
//   background: linear-gradient(90deg, transparent 0%, #48abe0 100%);
//   background: linear-gradient(135deg, #1976d2 0%, #512da8 50%, #e65100 100%),
//     radial-gradient(77.36% 256.97% at 77.36% 57.52%, #ff8800 0%, #330ae7 100%);
//   clip-path: ellipse(150% 87% at 93% 13%);
// }

// .background-gradient {
// background: linear-gradient(
//     0deg,
//     rgba(49, 27, 146, 0.8),
//     rgba(13, 71, 161, 0.8)
//   ),
//   radial-gradient(77.36% 256.97% at 77.36% 57.52%, #ff8800 0%, #330ae7 100%);
// clip-path: ellipse(100% 100% at 24% 4%);
// }

.background-gradient {
  background-image: url(../../../developsoft/images/landing/fondo-develop.jpg);
}

@mixin visibleDesktop() {
  @media screen and (min-width: 992px) {
    display: flex;
  }
  @media screen and (min-width: 480px) and (max-width: 991px) {
    display: none;
  }
  @media screen and (max-width: 480px) {
    display: none;
  }
}
.banner-contact {
  background-color: var(--surface-100);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-right: 10%;
  padding-left: 10%;
  height: 4rem;
  display: flex;
  flex-direction: column;

  .p-inputgroup {
    justify-content: flex-end;
  }
  .pi {
    font-size: 2rem;
  }
  p-chip {
    cursor: pointer;
    cursor: hand;
    .p-chip {
      @include visibleDesktop();
      background-color: var(--surface-100);
      color: var(--primary-color);
    }
    :hover {
      background-color: var(--secundary-color);
      color: var(--primary-color-text);
    }
  }
}

@mixin titlesBase() {
  font-family: "Poppins-Bold";
  font-weight: 900;
  padding: 0rem;
  margin: 0rem;
  color: var(--text-color-Logo);
  @media screen and (min-width: 1961px) {
    font-size: 4.5rem;
  }
  @media screen and (max-width: 1960px) {
    font-size: 3.5rem;
  }
  @media screen and (max-width: 991px) {
    font-size: 2.5rem;
  }
  @media screen and (max-width: 480px) {
    font-size: 1.7rem;
  }
}

@mixin imageLogo() {
  @media screen and (min-width: 1961px) {
    height: 4.5rem;
  }
  @media screen and (max-width: 1960px) {
    height: 3.5rem;
  }
  @media screen and (max-width: 991px) {
    height: 2.5rem;
  }
  @media screen and (max-width: 480px) {
    height: 2.5rem;
  }
}
.header-landing {
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  .nav-landing {
    background-color: transparent;
    .p-menubar {
      background: transparent;
    }
  }

  h1 {
    @include titlesBase();
  }
  img {
    @include imageLogo();
  }

  .header-landing {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px; /* Ajusta el espaciado según tus necesidades */
  }

  .menu-sidebar {
    display: none;
  }

  /* Media query para pantallas de 1300px o más */
  @media screen and (min-width: 1300px) {
    .menu-sidebar {
      display: block;
    }
  }
}

// h1 {
//   font-family: "Poppins-Bold";
//   font-style: italic;
//   font-weight: 900;
// }

.p-carousel-container {
  background: var(--surface-100);
  display: flex; /* Hacer que el contenedor sea un flex container */
  justify-content: center; /* Centrar horizontalmente el contenido */
  align-items: center; /* Centrar verticalmente el contenido (opcional) */
  // height: 30vh; /* Establecer la altura del contenedor para centrar verticalmente (opcional) */
}

.footer-v-line {
  border-left: thick solid var(--surface-100);
  height: 20rem;
  left: 40%;
  position: absolute;
  // @media screen and (max-width: 991px) {
  //   display: none;
  // }
}
.footer-logo-image {
  width: 70%;
  height: auto;
  @media screen and (min-width: 1280px) {
    width: 70%;
    height: auto;
  }
}

.offers-services {
  background: var(--surface-100);
}

@mixin tabviewResponsive() {
  @media screen and (min-width: 1961px) {
    .p-tabview-nav {
      justify-content: center;
    }
    .p-tabview-nav-container {
      display: flex;
    }
    .p-tabview-nav-content {
      display: contents;
    }
  }
  @media screen and (max-width: 1960px) {
    .p-tabview-nav {
      justify-content: center;
    }
    .p-tabview-nav-container {
      display: flex;
    }
    .p-tabview-nav-content {
      display: contents;
    }
  }
  @media screen and (max-width: 991px) {
    .p-tabview-nav {
      justify-content: center;
    }
    .p-tabview-nav-container {
      display: flex;
    }
    .p-tabview-nav-content {
      display: contents;
    }
  }
  @media screen and (max-width: 480px) {
    .p-tabview-nav-container {
      display: flex;
    }
    .p-tabview-nav {
      justify-content: center;
      display: contents;
    }
    .p-tabview-nav-content {
      display: contents;
    }
    .p-tabview-title {
      white-space: normal;
    }
  }
}

.landing-tabview {
  padding: 0rem;
  margin: 0rem;
  text-align: center;
  @include tabviewResponsive();
  // .p-tabview-nav-container {
  //   display: flex;
  // }
  // .p-tabview-nav {
  //   justify-content: center;
  // }
  // .p-tabview-nav-content {
  //   display: contents;
  // }
}

.landing-banner-button {
  background-color: var(--secundary-color);
  border-radius: 50%;
}

.landing-nosotros {
  /* Estilos para el encabezado */

  header {
    background-color: #333;
    color: #fff;
    padding: 20px;
    text-align: center;
  }

  header h1 {
    font-size: 36px;
    margin: 0;
  }

  /* Estilos para las secciones */
  section {
    padding: 20px;
    margin: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 5px;
  }

  section h2 {
    font-size: 24px;
    color: #333;
  }

  section h3 {
    font-size: 20px;
    color: #555;
  }

  section p {
    font-size: 16px;
    line-height: 1.5;
    color: #777;
  }

  /* Estilos para Misión y Visión */
  section h3:first-child {
    margin-top: 0;
  }

  /* Estilos para el equipo */
  div {
    margin: 0px 0;
    z-index: 1;
  }

  div h3 {
    font-size: 18px;
    color: #333;
  }

  div p {
    font-size: 16px;
    line-height: 1.5;
    color: #777;
  }
}

// Contact
.layout-footer-contact {
  transition: margin-left $transitionDuration;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 1rem;
}

.landing-background {
  background-image: url(../../../developsoft/images/landing/template/page-content/dots.png);
  background-size: cover;
  align-items: center;
  background-color: var(--surface-900);
}
