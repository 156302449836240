/* You can add global styles to this file, and also import other style files */

html {
  scroll-behavior: smooth;
}

$gutter: 1rem; //for primeflex grid system
@import "assets/layout/styles/layout/layout.scss";

/* PrimeNG */
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";

/* Fonts scss */
@import "assets/base/fonts/_fonts.scss";

// body {
//   // font-family: "Poppins-SemiBold";
//   font-family: "Poppins-Medium", sans-serif;
// }

// h1 {
//   font-family: "Poppins-Bold";
//   font-weight: 700;
//   color: #263238;
// }

// .titleLogo {
//   font-family: "Poppins-Bold";
//   font-weight: 700;
// }
