.register-data-user-animate {
  display: flex;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.banner-register-logo {
  cursor: pointer;
  padding-top: 0.3rem;
  .banner-register-logo-title {
    // visibility: hidden;
    font-size: 1.75rem;
    font-weight: bold;
    margin: -2.7rem 0 3rem 5.5rem;
  }
}
.banner-register-space {
  display: flex;
  justify-content: space-between;
  width: 900px;
}
.banner-register {
  background-color: #f5f5f5;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-right: 17%;
  padding-left: 15%;
  height: 6rem;
  display: flex;
  justify-content: center;

  .pi {
    font-size: 2rem;
  }
  p-chip {
    cursor: pointer;
    cursor: hand;
    .p-chip {
      @include visibleDesktop();
      background-color: #f5f5f5;
      color: var(--primary-color);
    }
    :hover {
      background-color: var(--secundary-color);
      color: var(--primary-color-text);
    }
  }
}

.background-register {
  width: auto;
  height: auto;
  background-image: url("../../../../developsoft/images/landing/tech-customer-register.jpg");
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
}
.background-blur-register {
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.3);
}

.form-register {
  width: 800px;
  margin: 0 auto; /* Centrar el formulario en la página */
  border-radius: 26px;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.4);
  padding: 0.3rem;
  .form-register-child {
    border-radius: 26px;
    padding: 3rem;
  }
  .register-title {
    padding: 1rem;
    font-size: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .profile-label {
    font-size: 1.25rem;
    color: var(--text-color-secondary);
  }
  .profile-input {
    color: var(--text-color);
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .profile-tag {
    padding-right: 0.25rem;
  }

  .container-profile {
    margin-right: 0rem;
    margin-left: 0rem;
  }

  .container-image-profile {
    justify-content: center;
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
    overflow: hidden;
    margin: 20px;
    display: flex;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.5);
    cursor: pointer;
    transition: 400ms;
  }

  .container-image-profile > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  /* Esto es opcional */
  .container-image-profile:hover {
    transform: scale(1.1);
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.5);
  }
}

.form-register > input {
  padding: 0.5rem;
}

@media (max-width: 991px) {
  .background-register {
    width: auto;
    height: 1570px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  .form-register {
    width: 576px;
    .form-register-child {
      padding: 3rem;
    }
    .register-title {
      padding: 0.5rem;
      font-size: 2.5rem;
      display: flex;
      align-items: center;
      text-align: center;
    }
    .container-image-profile {
      justify-content: center;
      width: 12rem;
      height: 12rem;
      border-radius: 50%;
      overflow: hidden;
      margin: 20px;
      box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.5);
      cursor: pointer;
      transition: 400ms;
    }

    .container-image-profile > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    /* Esto es opcional */
    .container-image-profile:hover {
      transform: scale(1.1);
      box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.5);
    }
  }
}

@media (max-width: 576px) {
  .background-register {
    width: auto;
    height: 1650px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  .banner-register-logo {
    cursor: pointer;
    padding-top: 0.3rem;
    .banner-register-logo-title {
      // visibility: hidden;
      font-size: 1.75rem;
      font-weight: bold;
      margin: -2.7rem 0 3rem 5.5rem;
    }
  }
  .banner-register-space {
    display: flex;
    justify-content: space-between;
    width: 900px;
  }
  .banner-register {
    background-color: #f5f5f5;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 37px;
    padding-left: 20px;
    height: 6rem;
    display: flex;
    justify-content: center;

    .pi {
      font-size: 2rem;
    }
    p-chip {
      cursor: pointer;
      cursor: hand;
      .p-chip {
        @include visibleDesktop();
        background-color: #f5f5f5;
        color: var(--primary-color);
      }
      :hover {
        background-color: var(--secundary-color);
        color: var(--primary-color-text);
      }
    }
  }

  .form-register {
    max-width: 95%;
    .form-register-child {
      padding: 2.5rem;
    }
    .register-title {
      padding: 0.5rem;
      font-size: 1.75rem;
      display: flex;
      align-items: center;
      text-align: center;
    }
    .container-image-profile {
      justify-content: center;
      width: 12rem;
      height: 12rem;
      border-radius: 50%;
      overflow: hidden;
      margin: 20px;
      box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.5);
      cursor: pointer;
      transition: 400ms;
    }

    .container-image-profile > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    /* Esto es opcional */
    .container-image-profile:hover {
      transform: scale(1.1);
      box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.5);
    }
  }
}
