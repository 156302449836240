.boxCountryCode {
  padding: 1rem 0.25rem 1rem 1rem;
  width: 18%;
  .p-dropdown .p-dropdown-trigger {
    background: transparent;
    color: #6c757d;
    width: 2.357rem;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
}

.boxPhone {
  padding: 1rem 1rem 1rem 0.25rem;
  width: 32%;
}

@media (max-width: 991px) {
  .boxCountryCode {
    width: calc(32% + 0.5rem);
  }
  .boxPhone {
    width: calc(68% - 0.5rem);
  }
}

@media (max-width: 576px) {
  .boxCountryCode {
    padding: 1rem 1rem 1rem 1rem;
    width: 100%;
  }
  .boxPhone {
    padding: 1rem 1rem 1rem 1rem;
    width: 100%;
  }
}
