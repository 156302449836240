$primary-color: #005c98;
$secondary-color: #505bda;

$color-background-start: rgb(70, 58, 203);
$color-background-end: rgb(44, 37, 119);
$color-background-middle: rgb(78, 65, 221);

$color-background-start-shadow: rgb(255, 255, 255);
$color-background-middle-shadow: rgba(185, 185, 185, 0.4);
$color-background-end-shadow: rgb(255, 255, 255);

.v-card {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  height: auto;
  background-image: url(../../../../developsoft/images/landing/template/page-content/dots-op2.png);
  background-color: #293235;
  background-size: contain;
  margin: 0;

  .qr-share {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;
  }

  @mixin qr-button-size() {
    @media screen and (min-width: 1961px) {
      --size-button: 4.5rem;
      --icon-button: 2.5rem;
      --font-size-button: 2.3rem;
    }
    @media screen and (max-width: 1960px) {
      --size-button: 4.5rem;
      --icon-button: 2.5rem;
      --font-size-button: 2.3rem;
    }
    @media screen and (max-width: 991px) {
      --size-button: 4.5rem;
      --icon-button: 2.5rem;
      --font-size-button: 2.3rem;
    }
    @media screen and (max-width: 480px) {
      --size-button: 4rem;
      --icon-button: 2.3rem;
      --font-size-button: 2rem;
    }
  }

  .qr-button {
    display: flex;
    justify-items: center;
    background: transparent;
    color: #70787a;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.4);
    border-radius: 50%;
    border-color: #fafafa;
    width: var(--size-button);
    height: var(--size-button);
    font-size: 2.5rem;
    cursor: pointer;
    .pi {
      font-size: var(--font-size-button);
    }
    @include qr-button-size();
    @media screen and (min-width: 1961px) {
      top: 0rem;
      left: 3rem;
    }
    @media screen and (max-width: 1960px) {
      top: 0rem;
      left: 3rem;
    }
    @media screen and (max-width: 991px) {
      top: 0rem;
      left: 3rem;
    }
    @media screen and (max-width: 480px) {
      top: 0rem;
      left: 3rem;
    }
  }

  .v-card-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1px;
    height: 100px;
  }

  .v-card-header-1 {
    display: grid;
    position: relative;
    background-image: url(../../../../developsoft/images/v-card/developsoft/orange-back-mobile-400.png);
    animation: upDown 5s linear infinite;
    transition: 2s;
    z-index: 2;
    height: 550px;
    background-size: contain;
    background-position: bottom;
    background-repeat: no-repeat;
  }
  .v-card-header-2 {
    display: grid;
    position: relative;
    background-image: url(../../../../developsoft/images/v-card/developsoft/orange-front-mobile-400.png);
    z-index: 3;
    height: 550px;
    background-size: contain;
    background-position: bottom;
    background-repeat: no-repeat;
  }
  .v-card-header-3 {
    display: grid;
    position: relative;
    background-image: url(../../../../developsoft/images/v-card/developsoft/blue-back-mobile-400.png);
    animation: upDown 4s linear infinite;
    transition: 3s;
    z-index: 4;
    height: 550px;
    background-size: contain;
    background-position: bottom;
    background-repeat: no-repeat;
  }
  .v-card-header-4 {
    display: grid;
    position: relative;
    background-image: url(../../../../developsoft/images/v-card/developsoft/blue-front-mobile-400.png);
    z-index: 5;
    height: 550px;
    background-size: contain;
    background-position: bottom;
    background-repeat: no-repeat;
  }
  @keyframes upDown {
    0%,
    100% {
      background-position-y: 230px;
    }
    50% {
      background-position-y: 240px;
    }
  }

  p-card {
    display: flex;
    justify-content: center;
    height: auto;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.4),
      0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
    border-radius: 15px;

    .p-card .p-card-body {
      padding: 0rem;
    }
    .p-card .p-card-content {
      padding-top: 0rem;
      padding-bottom: 0rem;
    }

    .v-card-header-background {
      background: #f5f5f5;
    }
    .v-card-header-section {
      height: 500px;
      .v-card-content-text {
        margin-top: 1rem;
        margin-bottom: 1rem;
        .v-card-name {
          font-size: 2rem;
          font-family: "Poppins-Bold";
          color: #ffffff;
          margin-bottom: 0.5rem;
        }
        .v-card-position {
          font-size: 1.2rem;
          line-height: 0.3rem;
          color: #ffffff;
        }
        .v-card-business {
          font-size: 1.2rem;
          line-height: 0.3rem;
          color: #ffffff;
        }
      }
    }
    .v-card-buttons-section {
      display: flex;
      align-items: center;
      background-color: #f5f5f5;
      padding: 0.5rem;
      justify-content: space-evenly;

      .v-card-layout-topbar-button {
        margin: 0.5rem;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        position: relative;
        color: #ffffff;
        border-radius: 50%;
        border-color: #448cff;
        width: 5rem;
        height: 5rem;
        cursor: pointer;
        transition: background-color 0.5s;
        background-color: #326fd1;
        &:hover {
          color: #ffffff;
          background-color: #f97316;
          border-color: #f97316;
        }

        &:focus {
          @include focused();
        }

        i {
          font-size: 2.5rem;
        }

        span {
          font-size: 1rem;
          display: none;
        }
      }
    }

    .v-card-web-section {
      text-align: left;
      padding-top: 2rem;
      padding-bottom: 2rem;
      padding-left: 3rem;
      padding-right: 3rem;
      background: linear-gradient(
        45deg,
        $color-background-start,
        $color-background-end 50%,
        $color-background-middle
      );

      background-size: 200% 200%; /* Aumenta el tamaño del gradiente */
      animation: moveGradient 5s linear infinite;

      .v-card-web-title {
        font-size: 1.75rem;
        color: #ffffff;
      }

      .v-card-web-text {
        margin-top: 1rem;
        margin-bottom: 1rem;
        font-size: 1rem;

        color: #ffffff;
      }
    }
    @keyframes moveGradient {
      0% {
        background-position: 0% 100%; /* Esquina inferior izquierda */
      }
      50% {
        background-position: 100% 0%; /* Esquina superior derecha */
      }
      100% {
        background-position: 0% 100%; /* Esquina inferior izquierda */
      }
    }
  }
}

qr-button .qrcodeImage {
  transform-style: preserve-3d;
  transition: 0.6s;
  .side {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    /* Fix Chrome rendering bug */
    transform: rotate(0deg) translateZ(1px);
  }
}

.qrcodeImage:hover {
  transform-style: preserve-3d;
  transition: 0.6s;
  .side {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    /* Fix Chrome rendering bug */
    transform: rotate(0deg) translateZ(1px);
  }
  .back {
    transform: rotateY(-180deg) translateZ(1px);
  }
}

.v-card-avatar {
  display: flex;
  justify-content: center;
  .container-image-profile {
    width: 12rem;
    height: 12rem;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.5);
    cursor: pointer;
    transition: 400ms;
  }

  .container-image-profile > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  /* Esto es opcional */
  .container-image-profile:hover {
    transform: scale(1.1);
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.5);
  }
}
.v-card-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1px;
}
