// Poppins
@font-face {
  font-family: "Poppins-Light";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(Poppins-Light.ttf) format("truetype");
}
@font-face {
  font-family: "Poppins-Regular";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(Poppins-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Poppins-Medium";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(Poppins-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "Poppins-SemiBold";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(Poppins-SemiBold.ttf) format("truetype");
}
@font-face {
  font-family: "Poppins-Bold";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(Poppins-Bold.ttf) format("truetype");
}

// Rubik
@font-face {
  font-family: "Rubik-Light";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(Rubik-Light.ttf) format("truetype");
}
@font-face {
  font-family: "Rubik-Regular";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(Rubik-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Rubik-Medium";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(Rubik-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "Rubik-SemiBold";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(Rubik-SemiBold.ttf) format("truetype");
}
@font-face {
  font-family: "Rubik-Bold";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(Rubik-Bold.ttf) format("truetype");
}
