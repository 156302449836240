.form-profile {
  .profile-label {
    font-size: 1.25rem;
    color: var(--text-color-secondary);
  }
  .profile-input {
    color: var(--text-color);
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .profile-tag {
    padding-right: 0.25rem;
  }

  .container-profile {
    margin-right: 0rem;
    margin-left: 0rem;
  }

  .container-image-profile {
    justify-content: center;
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
    overflow: hidden;
    margin: 20px;
    display: flex;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.5);
    cursor: pointer;
    transition: 400ms;
  }

  .container-image-profile > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  /* Esto es opcional */
  .container-image-profile:hover {
    transform: scale(1.1);
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.5);
  }
}

.form-profile > input {
  padding: 0.5rem;
}

@media (max-width: 991px) {
  .form-profile {
    .container-image-profile {
      justify-content: center;
      width: 12rem;
      height: 12rem;
      border-radius: 50%;
      overflow: hidden;
      margin: 20px;
      box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.5);
      cursor: pointer;
      transition: 400ms;
    }

    .container-image-profile > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    /* Esto es opcional */
    .container-image-profile:hover {
      transform: scale(1.1);
      box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.5);
    }
  }
}

@media (max-width: 576px) {
  .form-profile {
    .container-image-profile {
      justify-content: center;
      width: 12rem;
      height: 12rem;
      border-radius: 50%;
      overflow: hidden;
      margin: 20px;
      box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.5);
      cursor: pointer;
      transition: 400ms;
    }

    .container-image-profile > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    /* Esto es opcional */
    .container-image-profile:hover {
      transform: scale(1.1);
      box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.5);
    }
  }
}
