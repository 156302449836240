// Variables
$primary-color: #005c98;
$secondary-color: #505bda;
$bubble-color: rgba(0, 0, 0, 0.281);

// Mixin para las burbujas
@mixin bubble($size, $left, $animation-duration, $animation-delay) {
  width: $size + px;
  height: $size + px;
  left: $left;
  animation-duration: $animation-duration + s;
  animation-delay: $animation-delay + s;
}

// Estilos generales
// * {
//   margin: 0;
//   padding: 0;
//   box-sizing: border-box;
// }

// body {
//   font-family: "Roboto", sans-serif;
// }

.background-contenedor {
  width: 90%;
  max-width: 1200px;
  margin: auto;
}

// Fondo animado
.background-bg-animate {
  width: 100%;
  height: 100vh;
  background: linear-gradient(to right, $primary-color, $secondary-color);
  position: relative;
  overflow: hidden;
}

// Encabezado
.background-header-nav {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;

  .background-contenedor {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
  }

  h1 {
    color: #fff;
    font-family: "Gagalin";
  }

  nav a {
    color: #fff;
    text-decoration: none;
    margin-right: 10px;
  }
}

// Banner
.background-banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;

  .background-banner_title {
    h2 {
      color: #fff;
      font-size: 60px;
      font-weight: 800;
      margin-bottom: 20px;
    }

    .background-llamanos {
      color: #fff;
      font-size: 20px;
      text-decoration: none;
      display: inline-block;
      background: #1a2849;
      padding: 20px;
    }
  }

  .background-banner_img {
    animation: movimiento 2.5s linear infinite;

    img {
      width: 600px;
      display: block;
    }
  }
}

// Burbujas
.burbuja {
  border-radius: 50%;
  background: $bubble-color;
  opacity: 0.3;
  z-index: -1;
  position: absolute;
  bottom: -150;

  animation: burbujas 3s linear infinite;
}

// Definir estilos individuales para cada burbuja
.burbuja:nth-child(1) {
  @include bubble(80, 5%, 3, 3);
}

.burbuja:nth-child(2) {
  @include bubble(100, 35%, 3, 5);
}

.burbuja:nth-child(3) {
  @include bubble(20, 15%, 1.5, 7);
}

.burbuja:nth-child(4) {
  @include bubble(50, 90%, 6, 3);
}

.burbuja:nth-child(5) {
  @include bubble(70, 65%, 3, 1);
}

.burbuja:nth-child(6) {
  @include bubble(20, 50%, 4, 5);
}

.burbuja:nth-child(7) {
  @include bubble(20, 50%, 4, 5);
}

.burbuja:nth-child(8) {
  @include bubble(100, 52%, 5, 5);
}

.burbuja:nth-child(9) {
  @include bubble(65, 51%, 3, 2);
}

.burbuja:nth-child(10) {
  @include bubble(40, 35%, 3, 4);
}
// Animaciones
@keyframes burbujas {
  0% {
    bottom: 0;
    opacity: 0;
  }
  30% {
    transform: translateX(30px);
  }
  50% {
    opacity: 0.4;
  }
  100% {
    bottom: 100vh;
    opacity: 0;
  }
}

@keyframes movimiento {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(30px);
  }
  100% {
    transform: translateY(0);
  }
}

// fondo
.bg-develop-gradient {
  background: linear-gradient(
    45deg,
    var(--color-background-start),
    var(--color-background-middle) 50%,
    var(--color-background-end)
  );

  background-size: 200% 200%; /* Aumenta el tamaño del gradiente */
  animation: moveGradient var(--animation-background-duration) linear infinite;
}
@keyframes moveGradient {
  0% {
    background-position: 0% 100%; /* Esquina inferior izquierda */
  }
  50% {
    background-position: 100% 0%; /* Esquina superior derecha */
  }
  100% {
    background-position: 0% 100%; /* Esquina inferior izquierda */
  }
}

.bg-develop-login-white {
  width: 100%;
  height: 100vh;
  background: radial-gradient(
    ellipse at top 50% left 50%,
    rgba(255, 255, 255) 25%,
    rgba(255, 255, 255, 0.5) 50%,
    rgba(255, 255, 255, 0.2) 70%,
    transparent 100%
  );
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 480px) {
  .bg-develop-login-white {
    width: 100%;
    height: 100vh;
    padding-top: 31rem;
    padding-bottom: 31rem;
    background: radial-gradient(
      ellipse at top 50% left 50%,
      rgba(255, 255, 255, 0.6) 70%,
      transparent 100%
    );
  }
}
