.layout-landing-sidebar-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  color: var(--text-color);
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  cursor: pointer;
  transition: background-color $transitionDuration;
  background-color: var(--surface-100);
  &:hover {
    color: var(--surface-0);
    background-color: var(--primary-color);
  }

  &:focus {
    @include focused();
  }

  i {
    font-size: 1.5rem;
  }

  span {
    font-size: 1rem;
    display: none;
  }
}

.layout-landing-sidebar {
  &.p-sidebar {
    .p-sidebar-content {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }
  p-divider {
    display: block;
    height: 1px;
    padding: 0;
    margin: 7px -8px 8px;
    list-style: none;
    background: var(--primary-color-text);
    border: 0;
  }
}
